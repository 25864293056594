.LokiMons {
  text-decoration: none !important;
}
.lokimons-nav li a,
.lokimons-nav-wallet li button {
  text-decoration: none !important;
  font-size: 1.5em;
  padding: 4px;
}
.lokimons-nav li:not(:last-child):hover {
  text-decoration: none !important;
  background: green !important;
  border-radius: 3px;
  filter: drop-shadow(8px 12px 20px green) invert(1%);
}

.rpgui-content {
  /* padding: 12px 24px ; */
  background-image: linear-gradient(to right top, #000000, #0e0c0e, #171618, #1c1d20, #212529);
}

.rpgui-content a {
  color: #00c853 !important;
  /* text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000; */
}

.navbar-dark .navbar-brand {
  color: #fff !important;
  font-size: initial;
}

.navbar-nav {
  align-items: center;
}

.dropdown-menu {
  background-color: #343a40 !important;
}

.dropdown-item:hover {
  background: green !important;
  filter: drop-shadow(8px 12px 20px green) invert(1%);
}

.App {
  text-align: center;
}

.AppTitle {
  color: #6c757d;
  width: 100%;
  padding-left: 12px;
  padding-top: 2px;
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.p1 {
  text-align: end;
  width: 100%;
  color: #00c853;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  font-size: 1.75em;
}

.p2 {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  text-align: center;
  font-size: calc(5px + 0.5vmin);
  color: #00c853;
  width: 100%;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-tabs {
  margin-top: 4%;
}

.mon {
  display: block;
  float: left;
  padding: 0;
  margin: 2%;
  background-color: rgb(209, 203, 199) !important;
  border-radius: 6px;
}

.monName {
  color: silver !important;
  text-shadow: 2px 2px #ffffff;
  font-size: 0.85em !important;
}

label {
  /* line-height: 18px !important; */
  /* color:#6c757d !important */
  font-size: 0.65em !important;
}

.my-figure {
  /* border: 2px outset; */
  border-radius: 3px;
  margin: 0;
  padding: 3%;
  background-color: #ffe6b3;
  background-image: url(./sprites-copy/background/9.png);
  width: 15em;
  background-size: 280px 200px;
}

.monBox {
  border: 2px inset;
  background-color: #ffe;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.monImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 8em;
  /* this defines image height */
  height: 8em;
  /* this defines image height	 */
  margin-top: 2em;
  /* this defines image height */
  margin-bottom: 2em;
}

.monBackgroundImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.stat-area {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
}

.stat-line {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-line {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
}

.form-input {
  float: right;
  display: block;
}

.stat-label,
.form-label {
  display: inline;
}

.add-for-sale-label,
.remove-from-sale-label,
.sale-owner,
.sale-price,
.shareTo-owner,
.shared-owner {
  display: block;
  line-height: 2.25em;
  word-wrap: break-word;
  font-size: calc(4px + 0.5vmin);
  color: #fff;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  padding: 2px;
}

.add-for-sale-input {
  display: block;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.selling-div,
.buying-div,
.sharing-div {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 15em;
  padding: 3px;
  background: linear-gradient(45deg, black, transparent);
}

.fight-choice-div,
.breed-choice-div {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  max-width: fit-content;
}

.fightWith-area,
.fightAgainst-area {
  display: inline-block;
  width: 50%;
  margin-top: 2%;
  height: 100%;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
}
.border-gradient-green {
  border-image-source: linear-gradient(to left, #00c853, #b2ff59);
}

.fight-mons-area {
  height: 100%;
}

.breeding-area,
.sharing-area {
  display: block;
  width: 32.7em;
  margin-left: auto;
  margin-right: auto;
}

.add-for-sale-btn,
.remove-from-sale-btn,
.sale-btn,
.br-Choice-btn,
.fight-Choice-btn,
.fight-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15em;
  height: 40px;
}

.rpgui-content {
  overflow-y: auto !important;
}

.rpgui-content label,
.rpgui-content li {
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
  font-size: 0.5em;
}

.rpgui-dropdown-buy-pack {
  font-size: 16px !important;
  padding: 4px;
  margin-top: 12px !important;

  background-color: rgb(34, 36, 37) !important;
  color: rgb(209, 203, 199) !important;
  border-color: rgb(123, 107, 101) !important;
}

.tab-content {
  margin-top: 3%;
}

.nav-link.active {
  color: #f3a718 !important;
}

.nav-tabs .nav-link {
  border-top-color: rgb(222, 226, 230) !important;
  border-right-color: rgb(222, 226, 230) !important;
  border-bottom-color: rgb(255, 255, 255) !important;
  border-left-color: rgb(222, 226, 230) !important;
  background-color: rgb(34, 36, 37) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-top-color: rgb(222, 226, 230) !important;
  border-right-color: rgb(222, 226, 230) !important;
  border-bottom-color: rgb(34, 36, 37) !important;
  border-left-color: rgb(222, 226, 230) !important;
  background-color: rgb(34, 36, 37) !important;
  filter: drop-shadow(8px 12px 20px green) invert(1%);
}

.nav-link:hover {
  color: #f3a718 !important;
  text-decoration: none !important;
}

.winner-label {
  margin-left: 12px;
  font-size: 10px !important;
}

.nav {
  width: 100%;
  border-bottom-width: 0 !important;
}

.rpgui-content input,
.rpgui-content textarea {
  background: linear-gradient(45deg, black, transparent);
}

.wallet-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.user-info-column {
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.wallet-info-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.green-glow {
  filter: drop-shadow(8px 12px 20px green) invert(1%);
}

@media (min-width: 280px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .skellies {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }

  .with-burn {
    max-width: 100%;
  }

  .title-column,
  .user-info-column,
  .wallet-info-column {
    width: 100%;
  }

  .wallet-info-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .navbar {
    padding: 0.5rem 0.25rem !important;
  }

  .arena-chat-box {
    /* position: absolute; */
    width: 100%;
    /* bottom: 4%; */
    z-index: 999;
    position: fixed;
    bottom: 5%;
  }

  .arena-chat-box .rpgui-button.golden {
    padding-top: 15px;
  }
  .arena-chat-box textarea {
    height: 100%;
  }
  .arena-chat-textarea {
      display: var(--visible);
  }
      

  .arena-chat-controls {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 999;
  }

  .arena-chat-controls button {
    height: 3.75em;
  }
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .skellies {
    display: flex;
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }

  .title-column,
  .user-info-column,
  .wallet-info-column {
    width: 100%;
  }

  .wallet-info-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .skellies {
    display: flex;
    width: 20%;
  }

  .title-column,
  .user-info-column,
  .wallet-column {
    width: 100%;
  }
  .wallet-info-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .p1A {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 45%;
    margin-right: auto;
    width: 100%;
    color: #00c853;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
    font-size: 1.75em;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .skellies {
    display: flex;
    width: 20%;
  }

  .wallet-info-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .skellies {
    display: flex;
    width: 100%;
  }

  .wallet-info-column {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
