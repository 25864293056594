@media (min-width: 320px) {
  .breed-btn {
    width: 90%;
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .p1-breed {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    text-align: center;
    width: 100%;
    color: #00c853;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
    font-size: 1.75em;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .breeding-area {
    width: 48%;
    margin-left: 27%;
    margin-right: auto;
  }
  .breed-btn {
    width: 96%;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .breed-selected-mons-img {
    padding-left: 9px;
  }
  .breed-btn {
    width: 100%;
  }
  .breeding-area {
    width: 21%;
    margin-left: 40%;
    margin-right: auto;
  }
}
