@media (min-width: 320px) {
  .p1-shared-to-u {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    width: 100%;
    color: #00c853;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
    font-size: 1.75em;
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}
